import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    spotifyAuthToken: false,
    error: null,
    loading: false
};

const spotifyAuthCheckStart = (state, action) => {
    return updateObject( state, { loading: true } );
}

const spotifyAuthCheckSuccess = ( state, action ) => {
    return updateObject( state, {
        spotifyAuthToken: true,
        loading: false
    } );
};

const spotifyAuthCheckFail = ( state, action ) => {
    return updateObject( state, { 
        spotifyAuthToken: false,
        loading: false } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SPOTIFY_AUTH_CHECK_START: return spotifyAuthCheckStart( state, action );
        case actionTypes.SPOTIFY_AUTH_CHECK_SUCCESS: return spotifyAuthCheckSuccess( state, action );
        case actionTypes.SPOTIFY_AUTH_CHECK_FAIL: return spotifyAuthCheckFail( state, action );
        default: return state;
    }
    
};

export default reducer;