import React, {Component} from 'react'
import { connect } from 'react-redux';
import {
    withRouter
  } from 'react-router-dom';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions/index';
import classNames from "classnames";
import CustomizedInputs from '../../components/MaterialThemes/inputField'
import StyledButton from '../../components/MaterialThemes/button'
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import channelsPageStyle from "../../assets/jss/material-ui/views/channelJoinStyle.js";
import LinearProgressWithLabel from '../../components/CustomLinearProgress/LinearProgressWithLabel'
var qs = require('qs');

const useStyles = (channelsPageStyle);
class ChannelJoin extends Component {
    
    state = {
        controls: {
            channelCode: {
                elementType: 'input',
                elementConfig: {
                    type: 'channelCode',
                    placeholder: 'Channel Code'
                },
                value:  qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code || '',
                validation: {
                    required: false,
                    isEmail: false
                }, 
                valid: false,
                touched: false
            }
        },
        isSignup: true
    }
    
    inputChangedHandler = ( event, controlName ) => {
        
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value:  event.target.value,
                valid: true,
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    handleClick = (event) => {
        
        event.preventDefault();
        this.props.onChannelJoin(this.state.controls.channelCode.value, this.props.token)
        setTimeout(() => { 
            
            if (this.props.isChannelJoined) {
                this.props.history.push('/channel');
            }
        }, 6600);
        
        

    }

    render () {
        const { classes } = this.props;
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }
        let form =(
            <form onSubmit={this.handleClick}>
                {formElementsArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
            </form>
        ); 
        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        

        return (
            <div className={classes.ChannelJoin}>
                <Parallax
                    image={require("../../assets//sundara-1.jpg")}
                    filter="dark"
                    className={classes.parallax}
                />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.ChannelJoinBody}>
                        <div className={classes.ChannelJoinText}>
                            <h2>Please enter the code to join the channel</h2>
                        </div>
                        {/* {form} */}
                        <div className={classes.ChannelJoinContainer}>
                            <div className={classes.ChannelJoinInputContainer}>
                                <CustomizedInputs
                                    className={classes.margin}
                                    label="Code"
                                    id="custom-css-outlined-input"
                                    defaultValue= {this.state.controls.channelCode.value}
                                    onChange = {( event,channelCode ) => this.inputChangedHandler( event, "channelCode" )}
                                    />
                                </div>
                        
                                <br/>

                            
                        </div>
                        {this.props.progressbar ? 
                                
                                <div className={classes.joinButton} >
                                    <StyledButton label="Submit" primary={true}  onClick={(event) => this.handleClick(event)}>
                                        JOIN
                                    </StyledButton>
                           
                                    <LinearProgressWithLabel/>
                                </div>
                             : 
                                <div className={classes.joinButton} >
                                    <StyledButton label="Submit" primary={true}  onClick={(event) => this.handleClick(event)}>
                                        JOIN
                                    </StyledButton>
                                </div>
                                }    
                            <br/>
                    </div>
                    

                </div>
                
                <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        {/* <Favorite className={classes.icon} /> by{" "} */}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                />
            </div>
        );
    }
    

}
const mapStateToProps = state => {
    
    return {
        loading: state.auth.loading,
        progressbar: state.channels.progressbar,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        channelRedirectPath: state.channels.ChannelCreate !== null,
        token: state.auth.token,
        isChannelJoined: state.channels.channelJoined !== null
    };
};


const mapDispatchToProps = dispatch => {
    
    return {
        onChannelJoin: ( channelCode, token ) => dispatch( actions.joinChannel( channelCode, token ) ),
        onSetChannelRedirectPath: () => dispatch(actions.setChannelRedirectPath('/join'))
    };
};
ChannelJoin.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(ChannelJoin )));
;
