import React from 'react';
import Aux from '../../hoc/Aux/Aux'
import classes from './Layout.css'
import Toolbar from '../Navigation/Toolbar/Toolbar'
import { connect } from 'react-redux';

const layout =(props) =>{
    return (
        <Aux>
            {/* <div>Toolbar - Sidebar - Backdrop</div> */}
            <Toolbar 
            isAuth={props.isAuthenticated}/>
            <main className={classes.Content}>
                {props.children}
            </main>
        </Aux>
    
    
    );
    
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

export default connect( mapStateToProps )( layout );