import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Avatar from '@material-ui/core/Avatar';
const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: '"Asap Condensed","Roboto", "Helvetica", "Arial"',
    // padding: "20px",
    fontWeight: 700,
  },
  body: {
    fontWeight: 700,
    fontFamily: '"Asap Condensed","Roboto", "Helvetica", "Arial"',
  },
}))(TableCell);

const StyledTableBodyCell = withStyles((theme) => ({
  // head: {
  //   backgroundColor: theme.palette.common.black,
  //   color: theme.palette.common.white,
  // },
  body: {
    fontWeight: 500,
    fontFamily: '"Asap Condensed","Roboto", "Helvetica", "Arial"',
    padding: "20px"
  },
}))(TableCell);

const AvatarDiv = withStyles((theme) => ({
  root: {
    maxWidth : "130px",
    justifyContent:"spaceBetween",
    display: "flex"
    
  }
}))

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);



const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class CustomPaginationActionsTable extends React.Component {
  
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 25,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };
  

  render() {
    const { classes } = this.props;
    const columns = [
      { id: 'name', label: 'TITLE'},
      { id: 'artist', label: 'ARTIST'},
      { id: 'duration', label: 'DURATION' },
      // {
      //   id: 'tempo',
      //   label: 'BPM',
      //   // minWidth: 170,
      //   // align: 'right',
      //   format: (value) => value.toLocaleString('en-US'),
      // },
      
      {
        id: 'users',
        label: 'Users',
        // minWidth: 170,
        // align: 'right',
        // format: (value) => value.toFixed(2),
      },
      {
        id: 'more info',
        label: 'DETAILS',
        // minWidth: 170,
        // align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      }
    ];
    const rows = this.props.rows
    const {  rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.id}>
                  <StyledTableBodyCell >
                    {row.name} 
                  </StyledTableBodyCell>
                  <StyledTableBodyCell >{row.artists.toString()}</StyledTableBodyCell>
                  <StyledTableBodyCell >{row.duration}</StyledTableBodyCell>
                  {/* <StyledTableBodyCell >{row.tempo}</StyledTableBodyCell>
                  <StyledTableBodyCell component="th" scope="row">{row.popularity}</StyledTableBodyCell> */}
                  <StyledTableBodyCell>
                  <div style={
                    {display: 'flex',
                    maxWidth: '130px',
                    justifyContent: 'space-between'}}>
                    {row.userIds.length > 3 ? 
                      <div style={
                    {display: 'flex',
                    maxWidth: '130px',
                    justifyContent: 'space-between'}}>
                    <Avatar>H</Avatar>
                    <Avatar>H</Avatar>
                    <Avatar>H</Avatar>

                        {/* {row.userIds.map(r => (<Avatar>H</Avatar>)).slice(0, 2)} */}
                        <Avatar>...</Avatar>
                      </div>
                          
                          
                        :  row.userIds.map(r => (<Avatar>H</Avatar>))
                    }

                  </div>
                  
                  </StyledTableBodyCell>
                  <StyledTableBodyCell >
                  <Button color="primary">. . .</Button>  
                  </StyledTableBodyCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[25]}
                  
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomPaginationActionsTable);