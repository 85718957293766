import React, {Component} from 'react'
import {
    withRouter
  } from 'react-router-dom'; 
import { connect } from 'react-redux';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import CustomizedInputs from '../../components/MaterialThemes/inputField'
import StyledButton from '../../components/MaterialThemes/button'
import LinearProgressWithLabel from '../../components/CustomLinearProgress/LinearProgressWithLabel'

import channelCreatePageStyle from "../../assets/jss/material-ui/views/channelCreationStyle.js";
import Parallax from "../../components/Parallax/Parallax.js";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = (channelCreatePageStyle);

class ChannelCreation extends Component {
    state = {
        controls: {
            channelName: {
                elementType: 'input',
                elementConfig: {
                    type: 'channelName',
                    placeholder: 'Channel Name'
                },
                value: '',
                validation: {
                    required: false,
                    isEmail: false
                },
                valid: false,
                touched: false
            }
        },
        isSignup: true
    }
    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: true,
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    handleClick = (event) => {
        event.preventDefault();
        this.props.onChannelCreation(this.state.controls.channelName.value, this.props.token)
        if (this.props.isChannelCreated) {
            
            
            setTimeout(() => {  this.props.history.push('/channel')}, 6600);;
        }

    }
    render () {
        const { classes } = this.props;
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }
        let form =(
            <form onSubmit={this.handleClick}>
                {formElementsArray.map(formElement => (
                    <Input 
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        invalid={!formElement.config.valid}
                        shouldValidate={formElement.config.validation}
                        touched={formElement.config.touched}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                ))}
                <Button btnType="Success" >CREATE</Button>
            </form>
        ); 
        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        return (
            <div className={classes.ChannelCreate}>
                <Parallax
                    image={require("../../assets//sundara-1.jpg")}
                    filter="dark"
                    className={classes.parallax}
                />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.ChannelCreateBody}>
                        <div className={classes.ChannelCreateText}>
                            <h2>Enter the Channel name so we can get started! </h2>
                        </div>
                        <div className={classes.ChannelCreateContainer}>
                            <div className={classes.ChannelCreateInputContainer}>
                                <CustomizedInputs
                                    className={classes.margin}
                                    label="Name"
                                    id="custom-css-outlined-input"
                                    onChange = {( event,channelName ) => this.inputChangedHandler( event, "channelName" )}
                                    />
                                </div>
                        
                                <br/>

                            
                        </div>
                       
                            {this.props.progressbar ? 
                                <div className={classes.ChannelCreateButton} >
                                    <StyledButton label="Submit" primary={true}  disabled={true}>
                                        CREATE
                                    </StyledButton>
                                    <LinearProgressWithLabel/>
                                    </div>
                             : 
                                <div className={classes.ChannelCreateButton} >
                                    <StyledButton label="Submit" primary={true}  onClick={(event) => this.handleClick(event)}>
                                        CREATE
                                        
                                    </StyledButton>
                                 </div>
                                
                                }    
                    </div>
                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    
    return {
        loading: state.auth.loading,
        progressbar: state.channels.progressbar,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        isChannelCreated: state.channels.ChannelCreate !== null,
        channelRedirectPath: state.channels.channelRedirectPath,
        token: state.auth.token
    };
};

const mapDispatchToProps = dispatch => {
    
    return {
        onChannelCreation: ( channelName, token ) => dispatch( actions.createChannel( channelName, token ) ),
        onSetChannelRedirectPath: () => dispatch(actions.setChannelRedirectPath('/create'))
    };
};
    

ChannelCreation.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(ChannelCreation )));