import { Height } from "@material-ui/icons";

const navPageStyle = theme => ({
    root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: -12
      },
    
    rootToolBar: {
        backgroundColor: "black",
        minHeight: "50px"
    }
})

export default navPageStyle;
    // This group of buttons will be aligned to the right
    
  