import React, {Component} from 'react'
import { connect } from 'react-redux';
import {
    withRouter
  } from 'react-router-dom'; 
import { withStyles } from '@material-ui/core/styles';
import Input from '../../../components/UI/Input/Input';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';
import CustomizedInputs from '../../../components/MaterialThemes/inputField'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import TheatersIcon from '@material-ui/icons/Theaters';
import PublicIcon from '@material-ui/icons/Public';
import Group from "@material-ui/icons/Group";
// core components
import Footer from "../../../components/Footer/Footer.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import signupPageStyle from "../../../assets/jss/material-ui/views/signupPageStyle.js";

import image from "../../../assets/img/bg0.jpg";
const useStyles = (signupPageStyle);

class Register extends Component {
    
    state = {
        controls: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'name',
                    placeholder: 'Name'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
        isSignup: true
    }
    componentDidMount() {
        this.props.onSetAuthRedirectPath();
    }
    

    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }

     handleClick = event => {
        let path = this.props.location.state
        let codeStr = ''
        if (path.prevPath && path.prevPath.form) {
             codeStr = path.prevPath.from 
            
        }
        
        let code = codeStr.split('/').slice(-1)
        event.preventDefault();
        this.props.onRegister( this.state.controls.email.value, this.state.controls.password.value, code );
        // setTimeout(() => { 
        //     if (pathTo &&  pathTo.prevPath && pathTo.prevPath.from) {
        //         let codeStr = pathTo.prevPath.from
        //         let code = codeStr.split('/').slice(-1)
        //         this.props.history.push('/profile?code=' + code)
        //     }
        //     else {
        //         this.props.history.push('/profile')
        //     }
        // }, 1200);

    }

    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    
    render () {
        
        const { classes } = this.props;
        const formElementsArray = [];
        for ( let key in this.state.controls ) {
            formElementsArray.push( {
                id: key,
                config: this.state.controls[key]
            } );
        }

        let form = formElementsArray.map( formElement => (
            <Input
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                changed={( event ) => this.inputChangedHandler( event, formElement.id )} />
        ) );    
        if (this.props.loading) {
            form = <Spinner />
        }

        let errorMessage = null;

        if (this.props.error) {
            errorMessage = (
                <p>{this.props.error.message}</p>
            );
        }

        return (
            <section className={classes.AuthContainer}>
                 <div className={classes.pageHeader}
                    style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                    }}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Register</h2>
                                <CardBody>
                                <GridContainer justify="center">
                                    <GridItem xs={12} sm={5} md={5}>
                                    <InfoArea
                                        className={classes.infoArea}
                                        title="Music for everyone & by everyone"
                                        description="To stand out, we have inovated the right strategy so that you will never have to think about what music to play in a party, at the beach or in the car"
                                        icon={TheatersIcon}
                                        iconColor="success"
                                    />
                                    <InfoArea
                                        className={classes.infoArea}
                                        title="Built with AI"
                                        description="We've developed a algorithm using extensive AI and machine learning to provide the best experience of music based on your taste, company, mood and many more attributes"
                                        icon={PublicIcon}
                                        iconColor="primary"
                                    />
                                    <InfoArea
                                        className={classes.infoArea}
                                        title="Sync with Friends"
                                        description="We've created a way where you can create and collaborate a channel by synchronizing your's and your friends music taste  "
                                        icon={Group}
                                        iconColor="info"
                                    />
                                    </GridItem>
                                    <GridItem xs={12} sm={5} md={5}>
                                    <form className={classes.form}>
                                        <div >
                                            <CustomizedInputs
                                                className={classes.margin}
                                                label="Name"
                                                id="custom-css-outlined-input"
                                                onChange = {( event,email ) => this.inputChangedHandler( event, "name" )}
                                                />
                                        </div>
                                        <br/>
                                        <div >
                                            <CustomizedInputs
                                                className={classes.margin}
                                                label="Email"
                                                id="custom-css-outlined-input"
                                                onChange = {( event,email ) => this.inputChangedHandler( event, "email" )}
                                                />
                                        </div>
                                        <br/>
                                        <div>
                                            <CustomizedInputs
                                                type="password"
                                                label="password"
                                                hintText="Enter your Password"
                                                floatingLabelText="Password"
                                                onChange = {( event,password ) => this.inputChangedHandler( event, "password" )}
                                                />
                                            <br/>
                                        </div>
                                        <br/>
                                        <br/>
                                        <div className={classes.textCenter}>
                                        <Button 
                                        className={classes.registerButton}
                                        onClick={(event) => this.handleClick(event)}>
                                            Get started
                                        </Button>
                                        </div>
                                    </form>
                                    </GridItem>
                                </GridContainer>
                                </CardBody>
                            </Card>
                            </GridItem>
                        </GridContainer>
                        </div>
                 </div> 
                 <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        {/* <Favorite className={classes.icon} /> by{" "} */}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                /> 
             </section>
             
        );
    }
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRegister: ( email, password, code ) => dispatch( actions.register( email, password, code ) ),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath('/profile'))
    };
};
    
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(Register )));