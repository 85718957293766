export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const LOGOUT_START = 'AUTH_START';
export const LOGOUT_SUCCESS = 'AUTH_SUCCESS';
export const LOGOUT_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SPOTIFY_AUTH_CHECK_START = 'SPOTIFY_AUTH_CHECK_START'
export const SPOTIFY_AUTH_CHECK_SUCCESS = 'SPOTIFY_AUTH_CHECK_SUCCESS'
export const SPOTIFY_AUTH_CHECK_FAIL = 'SPOTIFY_AUTH_CHECK_FAIL'

export const FETCH_CHANNELS_START = 'FETCH_CHANNELS_START';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAIL = 'FETCH_CHANNELS_FAIL';

export const FETCH_CHANNELDETAIL_START = 'FETCH_CHANNELDETAIL_START';
export const FETCH_CHANNELDETAIL_SUCCESS = 'FETCH_CHANNELDETAIL_SUCCESS';
export const FETCH_CHANNELDETAIL_FAIL = 'FETCH_CHANNELDETAIL_FAIL';

export const FETCH_TRACKS_START = 'FETCH_TRACKS_START';
export const FETCH_TRACKS_SUCCESS = 'FETCH_TRACKS_SUCCESS';
export const FETCH_TRACKS_FAIL = 'FETCH_TRACKS_FAIL';

export const CREATE_CHANNELDETAIL_START = 'CREATE_CHANNEL_START';
export const CREATE_CHANNELDETAIL_SUCCESS = 'CREATE_CHANNEL_SUCCESS';
export const CREATE_CHANNELDETAIL_FAIL = 'CREATE_CHANNEL_FAIL';

export const JOIN_CHANNEL_START = 'JOIN_CHANNEL_START';
export const JOIN_CHANNEL_SUCCESS = 'JOIN_CHANNEL_SUCCESS';
export const JOIN_CHANNEL_FAIL = 'JOIN_CHANNEL_FAIL';

export const UPLOAD_SPOTIFY_CHANNEL_START = 'UPLOAD_SPOTIFY_CHANNEL_START';
export const UPLOAD_SPOTIFY_CHANNEL_SUCCESS = 'UPLOAD_SPOTIFY_CHANNEL_SUCCESS';
export const UPLOAD_SPOTIFY_CHANNEL_FAIL = 'UPLOAD_SPOTIFY_CHANNEL_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const GET_SPOTIFY_CHANNEL_START = 'GET_SPOTIFY_CHANNEL_START';
export const GET_SPOTIFY_CHANNEL_SUCCESS = 'GET_SPOTIFY_CHANNEL_SUCCESS';
export const GET_SPOTIFY_CHANNEL_FAIL = 'GET_SPOTIFY_CHANNEL_FAIL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_CHANNEL_REDIRECT_PATH = 'SET_CHANNEL_REDIRECT_PATH';