import * as actionTypes from './actionTypes';
import axios from 'axios';
import { browserHistory } from 'react-router'
export const fetchChannelsSuccess = ( channels ) => {
    return {
        type: actionTypes.FETCH_CHANNELS_SUCCESS,
        channels: channels
    };
};

export const fetchChannelsFail = ( error ) => {
    return {
        type: actionTypes.FETCH_CHANNELS_FAIL,
        error: error
    };
};

export const fetchChannelsStart = () => {
    return {
        type: actionTypes.FETCH_CHANNELS_START
    };
};

export const fetchChannelDetailStart = () => {
    return {
        type: actionTypes.FETCH_CHANNELDETAIL_START
    };
}

export const fetchChannelDetailFail = (error) => {
    return {
        type: actionTypes.FETCH_CHANNELDETAIL_FAIL,
        error: error
    };  
}

export const fetchChannelDetailSuccess = ( channeldetail ) => {
    return {
        type: actionTypes.FETCH_CHANNELDETAIL_SUCCESS,
        channeldetail: channeldetail
    };
};

export const createChannelDetailStart = () => {
    return {
        type: actionTypes.CREATE_CHANNELDETAIL_START
    };
}

export const createChannelDetailFail = (error) => {
    return {
        type: actionTypes.CREATE_CHANNELDETAIL_FAIL,
        error: error
    };  
}

export const createChannelDetailSuccess = ( data ) => {
    return {
        type: actionTypes.CREATE_CHANNELDETAIL_SUCCESS,
        channelCreate: data,
    };
};


export const joinChannelStart = () => {
    return {
        type: actionTypes.JOIN_CHANNEL_START
    };
}

export const joinChannelFail = (error) => {
    return {
        type: actionTypes.JOIN_CHANNEL_FAIL,
        error: error
    };  
}

export const joinChannelSuccess = ( data ) => {
    
    return {
        type: actionTypes.JOIN_CHANNEL_SUCCESS,
        channelJoined: data
    };
};

export const uploadSpotifyChannelStart = () => {
    return {
        type: actionTypes.UPLOAD_SPOTIFY_CHANNEL_START
    };
}

export const uploadSpotifyChannelFail = (error) => {
    return {
        type: actionTypes.UPLOAD_SPOTIFY_CHANNEL_FAIL,
        error: error
    };  
}

export const uploadSpotifyChannelSuccess = ( data ) => {
    return {
        type: actionTypes.UPLOAD_SPOTIFY_CHANNEL_SUCCESS,
        channel: data
    };
};

export const getSpotifyChannelStart = () => {
    return {
        type: actionTypes.GET_SPOTIFY_CHANNEL_START
    };
}

export const getSpotifyChannelFail = (error) => {
    return {
        type: actionTypes.GET_SPOTIFY_CHANNEL_FAIL,
        error: error
    };  
}

export const getSpotifyChannelSuccess = ( data ) => {
    return {
        type: actionTypes.GET_SPOTIFY_CHANNEL_SUCCESS,
        spotifyPlaylists: data
    };
};


export const setChannelRedirectPath = (path) => {
    return {
        type: actionTypes.SET_CHANNEL_REDIRECT_PATH,
        path: path
    };
};


export const fetchChannels = (token) => {
    return async dispatch => {
        try {
            dispatch(fetchChannelsStart());
            let apiBaseUrl = 'https://api.moowzi.com/channel/infoByUserId';
            let response = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": token
                },
                mode: 'no-cors'
                })
           
            if(response.data.statusCode===200) {
                dispatch(fetchChannelsSuccess(response.data.body));
            }
            else if (response.data.statusCode===400) {
                alert(response.data.error)
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchChannelsFail(error));
           
        }
    };
};

export const getSpotifyChannels = (token) => {
    return async dispatch => {
        try {
            dispatch(getSpotifyChannelStart());
            let apiBaseUrl = 'https://api.moowzi.com/playlists/spotify/me/list';
            let response = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": token
                },
                mode: 'no-cors'
                })
            if (response.statusCode === 200) {
                dispatch(getSpotifyChannelSuccess(response.data.body));
            }
        }
        catch (error) {
            dispatch(getSpotifyChannelFail(error));
           
        }
    };
};
export const fetchChannelDetail = (channelCode) => {
    return async dispatch => {
        try {
            dispatch(fetchChannelDetailStart());

            let apiBaseUrl = 'https://api.moowzi.com/channel/detail/'+ channelCode;
            let response_api = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                }
                })
            if (response_api.statusCode === 200) {
                const data = response_api.data.Items[0];
                dispatch(fetchChannelDetailSuccess(data));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchChannelDetailFail(error));
        }
    }

}

export const createChannel = (channelname, token) => {
    return async dispatch => {
        try {
            dispatch(createChannelDetailStart());
            const channelData = JSON.stringify({
                'channelName': channelname
            })
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
            var mode = 'no-cors'
            let response_api_post = await axios.post('https://api.moowzi.com/channel/create', channelData, {headers: headers}, mode);
            if (response_api_post.data.statusCode === 200) {
                const data = response_api_post.data.body;
                setTimeout(() => {
                    dispatch(createChannelDetailSuccess(data));
                  }, 6000);
               
            }
            else if (response_api_post.data.statusCode===400) {
                alert(response_api_post.data.error)
            }
        }
        catch (error) {
            console.log(error);
            dispatch(createChannelDetailFail(error));
        }
    }

}

export const joinChannel = (channelCode, token) => {
    return async dispatch => {
        try {
            dispatch(joinChannelStart());
            const channelJoinData = JSON.stringify({
                'code': channelCode
            })
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
            // var mode = 'no-cors'
            let response_api_post = await axios.post('https://api.moowzi.com/channel/join', channelJoinData, {headers: headers});
            if (response_api_post.data.statusCode === 200) {
               
                const data = response_api_post.data.body;
                setTimeout(() => {
                    dispatch(joinChannelSuccess(data));
                  }, 6000);
               
            }
        }
        catch (error){
            console.log(error);
            dispatch(joinChannelFail(error));
        }
    }
}

export const uploadSpotifyChannel = (channelId, token) => {
    return async dispatch => {
        try {
            dispatch(uploadSpotifyChannelStart());
            const channelUploadData = JSON.stringify({
                'channelId': channelId
            })
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'x-access-token': token
            }
            // var mode = 'no-cors'
            let response_api_post = await axios.post('https://api.moowzi.com/channel/upload', channelUploadData, {headers: headers});
            if (response_api_post.data.statusCode === 200) {
                const data = response_api_post.data.body;
                dispatch(uploadSpotifyChannelSuccess(data));
            }
        }
        catch (error){
            console.log(error);
            dispatch(uploadSpotifyChannelFail(error));
        }
    }
}