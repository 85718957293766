import React, {Component} from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter
} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { connect } from 'react-redux';
import Layout from './components/Layout/layout'
import Homepage from './containers/HomePage/Homepage'
import About from './components/About/About'
import Auth from './containers/Auth/Auth';  
import Profile from './containers/Profile/Profile'
import Logout from './containers/Auth/Logout/Logout';
import Register from './containers/Auth/Register/Register';
import Login from './containers/Auth/Login/Login';
import Channels from './containers/Channels/Channels'
import ChannelDetail from './containers/ChannelDetail/ChannelDetail'
import ChannelCreation from './containers/ChannelCreation/ChannelCreation'

import * as actions from './store/actions/index';
import ChannelJoin from './containers/ChannelJoin/ChannelJoin';

class App extends Component{
  componentDidMount () {
    this.props.onTryAutoSignup();
  }


  render () {
      let routes = (
        <Switch>
          <Route path="/auth" exact component={Auth} />
          <Route path="/signin" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route path="/" exact component={Homepage} />
          <Route path="/about" exact component={About} />
          <ProtectedRoute path="/homepage" exact component={Homepage} />
          <ProtectedRoute path="/profile"  exact component={Profile} />
          <ProtectedRoute path="/channel" exact component={Channels} />
          <ProtectedRoute path="/logout" component={Logout} />
          <ProtectedRoute path="/channel/create"  exact component={ChannelCreation} />
          <ProtectedRoute path="/channel/join"   component={ChannelJoin} />
          <ProtectedRoute path="/channel/join/:code"   component={ChannelJoin} />
          <ProtectedRoute path="/channel/:channelId" exact component={ChannelDetail} />
          <Redirect to="/" />
        </Switch>
      );
    
    return (
      
      <div>
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );

