import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    withRouter
  } from 'react-router-dom';
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import StyledButton from '../../components/MaterialThemes/button'
import CustomPaginationActionsTable from '../../components/MaterialThemes/table'
import shoppingCartStyle from "../../assets/jss/material-ui/views/shoppingCartStyle.js";
import classNames from "classnames";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";

const useStyles = (shoppingCartStyle);
class ChannelDetail extends Component {
    componentDidMount () {
        let path = this.props.location.pathname.split('/')
        
        this.props.onFetchTracks(this.props.token,path[(path.length)-1] );
        this.props.onFetchChannelDetail(path[(path.length)-1] );

    }

    onUploadSpotifyClick = () => {
        let path = this.props.location.pathname.split('/')
        this.props.onSpotifyUploadChannel( path[(path.length)-1], this.props.token)

    }
    render() {
        const { classes } = this.props;
        let tracks = <Spinner />;
        if ( !this.props.loading && typeof this.props.tracks !== "undefined" && this.props.tracks.length > 0) {
            tracks =  <CustomPaginationActionsTable rows={this.props.tracks}></CustomPaginationActionsTable>
        }
        
        return (
          
                
                <div className={classes.ChannelDetail}>
                    <Parallax
                    image={require("../../assets/img/clint-mckoy.jpg")}
                    filter="dark"
                    className={classes.parallax}
                    />
                    <div className={classNames(classes.main, classes.mainRaised)}>
                        <div className={classes.container}>
                            <div className={classes.syncButtonContainer}>
                                <StyledButton className={classes.syncButton} label="Submit" primary={true}  onClick={this.onUploadSpotifyClick}>
                                    SYNC WITH SPOTIFY
                                </StyledButton>
                            </div>
                            <div className={classes.tracksTable}>
                            <Card plain>
                                <CardBody table>
                                    {tracks} 
                                </CardBody>
                            </Card>
                            </div>
                            
                        </div>     
                    </div>
    
                    <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                />
                </div>
            
            
        )
    }
}
const mapStateToProps = state => {
    return {
        channeldetail: state.channels.channeldetail,
        tracks: state.tracks.tracks,
        loading: state.loading,
        token: state.auth.token
    };
};
const mapDispatchToProps = (dispatch, props ) => {
    
    return {
        onFetchChannelDetail: (channelId) => dispatch( actions.fetchChannelDetail(channelId )),
        onFetchTracks: (token, channelId) => dispatch(actions.fetchTracks(token,channelId)),
        onSpotifyUploadChannel: (channelId, token) => dispatch(actions.uploadSpotifyChannel(channelId, token ))
    };
};

ChannelDetail.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(ChannelDetail )));



