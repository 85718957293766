import * as actionTypes from './actionTypes';
// import axios from '../../axios-channel';
import axios from 'axios';

export const fetchTracksSucuess = ( tracks ) => {
    return {
        type: actionTypes.FETCH_TRACKS_SUCCESS,
        tracks: tracks
    };
};

export const fetchTracksFail = ( error ) => {
    return {
        type: actionTypes.FETCH_TRACKS_FAIL,
        error: error
    };
};

export const fetchTracksStart = () => {
    return {
        type: actionTypes.FETCH_TRACKS_START
    };
};


export const fetchTracks = (token, channelId) => {
    return async dispatch => {
        try {
            dispatch(fetchTracksStart()); 
            let apiBaseUrl = 'https://api.moowzi.com/channel/populate/' + channelId
            let response = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": token
                },
                mode: 'no-cors'
                })
            if(response.data.statusCode===200) {
                dispatch(fetchTracksSucuess(response.data.body));
            }    

        }
        catch(error) {
           
        }

    }
}