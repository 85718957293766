import axios from 'axios';
import * as actionTypes from './actionTypes';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, userId) => {
    return  {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};
export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    };
};

export const registerSuccess = (token, userId) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const registerFail = (error) => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: error
    };
};
export const logoutStart = () => {
    return {
        type: actionTypes.LOGOUT_START
    };
};

export const logoutSuccess = () => {
    return  {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const logoutFail = (error) => {
    return {
        type: actionTypes.LOGOUT_FAIL,
        error: error
    };
};

export const logout = () => {
    return async dispatch => {
        try{
            dispatch(logoutStart());
            localStorage.removeItem('isAuthed');
            localStorage.removeItem('token');
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('userId');
            localStorage.removeItem('state');
           
            let apiBaseUrl = 'https://api.moowzi.com/user/logout';
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
            var mode = 'no-cors'
            const response = await axios.post(apiBaseUrl, headers, mode)
            if(response.data.statusCode === 200) {
                localStorage.clear();
                window.localStorage.clear();
                dispatch(logoutSuccess())
            }
        }
        catch(error) {
            dispatch(logoutFail(error));
        }
    }
};


export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const auth = (email, password) =>{
    return async dispatch => {
        try
        {
            dispatch(authStart());
            const authData = {
                email: email,
                password: password,
                returnSecureToken: true
            };
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
            var mode = 'no-cors'
            let apiBaseUrl = 'https://api.moowzi.com/user/signin';
            const response = await axios.post(apiBaseUrl, authData, headers, mode)

            if(response.data.statusCode === 200) {
                localStorage.setItem('token', response.data.body.userAccessToken);
                const expirationDate = new Date(response.data.body.expiresIn * 1000);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.body.userID);
                
                if (localStorage.getItem('token')) {
                    dispatch(authSuccess(response.data.body.userAccessToken, response.data.body.userID));
                    
                }
            } 
            else if(response.data.statusCode === 204)
            {
                alert("username password do not match")
            }
            else
            {
                alert("Username does not exist");
            }
        }
        catch (error) {
            dispatch(authFail(error));
        }
    }
};
export const register = (email, password, code) =>{
    return async dispatch => {
        try
        {   
            dispatch(registerStart());
            const authData = {
                email: email,
                password: password,
                returnSecureToken: true
            };
            var headers = {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
            var mode = 'no-cors'
            const apiBaseUrl = 'https://api.moowzi.com/user/register';
            const response = await axios.post(apiBaseUrl, authData, headers, mode)
            if(response.data.statusCode === 200) {
                
                const expirationDate = new Date(new Date().getTime() + response.data.body.expiresIn * 1000);
                localStorage.setItem('token', response.data.body.userAccessToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', response.data.body.userID);
                if (localStorage.getItem('token')) {
                    setTimeout(() => { 
                        dispatch(registerSuccess(response.data.body.userAccessToken, response.data.body.userID));
                        window.location.href = `https://api.moowzi.com/spotifyauth?userAccessToken=${response.data.body.userAccessToken}&path=${code}`
                        
                        dispatch(checkAuthTimeout(response.data.body.expiresIn));
                    }, 2000);
                    
                }
                
            
            } 
            else if(response.data.statusCode === 204)
            {
                
                alert("username password do not match")
            }
            else
            {
                
                alert("Username does not exist");
            }
        }
        catch (error) {
            dispatch(registerFail(error));
        }
    }
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
            }   
        }
    };
};