import React, {Component} from 'react'
import { connect } from 'react-redux';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import Profile from '../Profile/Profile';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import  createMuiTheme from '@material-ui/core/styles/createMuiTheme'; 
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { makeStyles } from '@material-ui/core/styles';
import CustomizedInputs from '../../components/MaterialThemes/inputField'
import StyledButton from '../../components/MaterialThemes/button'
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import classes from './Auth.css'

 

class Auth extends Component {
    
    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        }
    }
    componentDidMount() {
        // this.props.onSetAuthRedirectPath();
    }
    
    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }

    handleLoginClick = event => {
        event.preventDefault();
        this.props.history.push('/signin')

    }
    handleRegisterClick = event => {
        event.preventDefault();
        this.props.history.push('/register')

    }
    handleClick = event => {
        
        console.log("We have the state", this.state)
        this.props.onAuth( this.state.controls.email.value, this.state.controls.password.value );
        // this.props.history.push('/channel');
        let authRedirect = null;
        console.log("Auth Check at Login Level after update", this.props)
        if (this.props.isAuthenticated) {
            this.props.history.push('/channel')
            console.log("Did we reach redirect",this.props )
            authRedirect = <Redirect to={this.props.authRedirectPath}/>
            
        }
        event.preventDefault();

    }

    inputChangedHandler = ( event, controlName ) => {
        console.log("This is the control name", controlName)
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    
    render () { 
        return (
            <div className={classes.Auth}>
                <MuiThemeProvider >
                    <div className={classes.LoginHeaderContainer}>
                        <h1>Enhance Your Experience with a Personal Profile and to get started on this Exciting adventure</h1>
                    </div>

                    <div className={classes.LoginContainer}>
                  

                        <div className={classes.LoginButtonContainer}>
                            <StyledButton label="Submit" primary={true}  onClick={(event) => this.handleLoginClick(event)}>
                                SIGNIN
                            </StyledButton>
                        </div>

                        <div className={classes.LoginButtonContainer}>
                            <StyledButton label="Submit" primary={true}  onClick={(event) => this.handleRegisterClick(event)}>
                                REGISTER
                            </StyledButton>
                        </div>
                    </div>

                </MuiThemeProvider>
                    
             </div>
        );
    }
}
const mapStateToProps = state => {
    console.log("Auth page map state to props", state)
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        // authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( email, password ) => dispatch( actions.auth( email, password ) ),
        // onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath('/channel'))
    };
};
    
export default connect( mapStateToProps, mapDispatchToProps )( Auth );