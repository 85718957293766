import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


// core components

import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Parallax from "../../components/Parallax/Parallax.js";

import landingPageStyle from "../../assets/jss/material-ui/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionWork from "./Sections/SectionWork.js";

const useStyles = (landingPageStyle);
class Homepage extends Component {
    render() {
      const { classes } = this.props;
        return (
            <div>

      <Parallax image={require("../../assets/silent-disco-2.jpeg")} filter="dark">
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              {/* <h1 className={classes.title}>THE WORLD IS FULL OF MUSIC</h1>
              <h4>
              To stand out, you need the right strategy, the right creative and the right app in your corner. Since 2011, we've been
                            the premier digital media partner for both talent and brands, currently serving over 2,500 talent and 400 brand clients.
              </h4> */}
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          {/* <SectionTeam /> */}
          <SectionWork />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.mousaic.com/"
                    target="_blank"
                    className={classes.block}
                  >
                    Moowzi 
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.mousaic.com/"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="https://www.mousaic.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.mousaic.com/"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , made with{" "}
              <a
                href="https://www.mousaic.com/"
                target="_blank"
              >
                Moowzi
              </a>{" "}
              for a better web.
            </div>
          </div>
        }
      />
    </div>
                   
        );
    }

};

Homepage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default connect() ( withStyles(useStyles)(Homepage )) ;