import React, {Component} from 'react'
import {
    withRouter
  } from 'react-router-dom';
  
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import { Redirect } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import CustomizedInputs from '../../../components/MaterialThemes/inputField'
import { withStyles } from '@material-ui/core/styles';

// @material-ui/core components
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Footer from "../../../components/Footer/Footer.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import loginPageStyle from "../../../assets/jss/material-ui/views/loginPageStyle.js";

import image from "../../../assets/img/bg0.jpg";
const useStyles = (loginPageStyle);

class Auth extends Component {
    
    state = {
        controls: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: 'Mail Address'
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Password'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 6
                },
                valid: false,
                touched: false
            }
        },
        isSignup: true,
    }
    componentDidMount() {
        this.props.onSetAuthRedirectPath();
    }
    checkValidity ( value, rules ) {
        let isValid = true;
        if ( !rules ) {
            return true;
        }

        if ( rules.required ) {
            isValid = value.trim() !== '' && isValid;
        }

        if ( rules.minLength ) {
            isValid = value.length >= rules.minLength && isValid
        }

        if ( rules.maxLength ) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if ( rules.isEmail ) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test( value ) && isValid
        }

        if ( rules.isNumeric ) {
            const pattern = /^\d+$/;
            isValid = pattern.test( value ) && isValid
        }

        return isValid;
    }

     handleClick = (event)=> {
        let pathTo = this.props.location.state
        event.preventDefault();
        this.props.onAuth( this.state.controls.email.value, this.state.controls.password.value, this.state.isSignup );
        setTimeout(() => { 
            if (pathTo &&  pathTo.prevPath && pathTo.prevPath.from) {
                
                this.props.history.push(pathTo.prevPath.from)
            } else { this.props.history.push('/channel')}
           
        }, 500);
    }

    inputChangedHandler = ( event, controlName ) => {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                valid: this.checkValidity( event.target.value, this.state.controls[controlName].validation ),
                touched: true
            }
        };
        this.setState( { controls: updatedControls } );
    }
    
    render () {
        
        const { classes } = this.props;
        return (
            <div >
                <div
                    className={classes.pageHeader}
                    style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                    }}
                >
                    <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <form className={classes.form}>
                            <CardHeader
                                color="primary"
                                signup
                                className={classes.cardHeader}
                            >
                                <h4 className={classes.cardTitle}>Login</h4>

                            </CardHeader>
                            <CardBody signup>
                                <div>
                                <CustomizedInputs
                                    className={classes.margin}
                                    label="Email"
                                    id="custom-css-outlined-input"
                                    onChange = {( event,email ) => this.inputChangedHandler( event, "email" )}
                                    />
                                </div>
                                
                                <br/>

                                <div>
                                    <CustomizedInputs
                                    type="password"
                                    label="password"
                                    hintText="Enter your Password"
                                    floatingLabelText="Password"
                                    onChange = {( event,password ) => this.inputChangedHandler( event, "password" )}
                                        />
                                </div>
                                
                            </CardBody>
                            <div className={classes.textCenter}>
                                <Button 
                                simple color="primary" 
                                size="lg"
                                onClick={(event) => this.handleClick(event)}>
                                Get started
                                </Button>
                            </div>
                            </form>
                        </Card>
                        </GridItem>
                    </GridContainer>
                    </div>       
                </div>
                <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                />
             </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( email, password, isSignup ) => dispatch( actions.auth( email, password, isSignup ) ),
        onSetAuthRedirectPath: (path) => dispatch(actions.setAuthRedirectPath(path)),
    };
};

Auth.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(Auth )));