import * as actionTypes from './actionTypes';
import axios from 'axios';

export const fetchUserSuccess = ( userInfo ) => {
    return {
        type: actionTypes.FETCH_USER_SUCCESS,
        user: userInfo
    };
};

export const fetchUserFail = ( error ) => {
    return {
        type: actionTypes.FETCH_USER_FAIL,
        error: error
    };
};

export const fetchUserStart = () => {
    return {
        type: actionTypes.FETCH_USER_START
    };
};

export const fetchUser = (token) => {
    return async dispatch => {
        try {
            dispatch(fetchUserStart());
            let apiBaseUrl = 'https://api.moowzi.com/user/profile';
            let response = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": token
                },
                mode: 'no-cors'
                })
            if(response.data.statusCode===200) {
                dispatch(fetchUserSuccess(response.data.body));
            }
        }
        catch (error) {
            console.log(error);
            dispatch(fetchUserFail(error));
           
        }
    };
};