import React, { Component } from 'react';

import NavigationItem from './NavigationItem/NavigationItem';
import { withRouter} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import navPageStyle from "../../../assets/jss/material-ui/views/navItemsStyle.js";

const useStyles = (navPageStyle);

class navigationItems extends Component {
    handleIconClicks = name => () => {
        console.log(name);
        this.props.history.push('/')
    }
    render() {
        const { classes } = this.props;
        return (
                <ul className={classes.NavigationItems}>
        
                {
                    !this.props.isAuthenticated ? 
                    (
                        <div className={classes.root}>
                            <AppBar >
                                <Toolbar  className={classes.rootToolBar} >
                                <IconButton  onClick={this.handleIconClicks('detail')} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                    <MenuIcon  />
                                </IconButton>
                                
                                <section className={classes.rightToolbar}>
                                    <NavigationItem link="/signin" prevPath={this.props.location.state} >Login</NavigationItem>
                                    <NavigationItem link="/register" prevPath={this.props.location.state} >Register</NavigationItem>
                                </section>
                                </Toolbar>
                            </AppBar>
                        </div>
                    )
                    :
                    (
                        
                        <div className={classes.root}>
                            <AppBar >
                                <Toolbar className={classes.rootToolBar} >
                                <IconButton onClick={this.handleIconClicks('detail')} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                    <MenuIcon />
                                </IconButton>
                                
                                <section className={classes.rightToolbar}>
                                    <NavigationItem link="/channel">Channels</NavigationItem>
                                    <NavigationItem link="/profile">Profile</NavigationItem>
                                    <NavigationItem link="/logout">Logout</NavigationItem>
                                </section>
                                </Toolbar>
                            </AppBar>
                        </div>
                    )
                }
        </ul>
        )

    }
}
navigationItems.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(( withStyles(useStyles)(navigationItems )));

