import axios from 'axios';

import * as actionTypes from './actionTypes';

export const spotifyAuthCheckStart = () => {
    return {
        type: actionTypes.SPOTIFY_AUTH_CHECK_START
    }

};
export const spotifyAuthCheckSuccess = (spotifyAuthToken) => {
    return {
        type: actionTypes.SPOTIFY_AUTH_CHECK_SUCCESS,
        spotifyAuthToken: spotifyAuthToken
    };
};

export const spotifyAuthCheckFail = (error) => {
    return {
        type: actionTypes.SPOTIFY_AUTH_CHECK_FAIL,
        error: error
    };
};

export const spotifyAuthCheck = (token) => {
    return async dispatch => {
        try {
            dispatch(spotifyAuthCheckStart());
            let apiBaseUrl = 'https://api.moowzi.com/user/profile';
            let response = await axios.get(apiBaseUrl,{
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": token
                },
                mode: 'no-cors'
                })
            if(response.data.statusCode===200 && response.data.body.spotifyauthtoken ) {
                dispatch(spotifyAuthCheckSuccess(response.data.body.spotifyauthtoken));
            }else {
                throw new Error("Spotify not authenticated")
            }
        }
        catch (error) {
            console.log(error);
            dispatch(spotifyAuthCheckFail(error));
           
        }
    }
}
