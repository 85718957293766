import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withRouter,
    Link
  } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as actions from '../../store/actions/index';


import classNames from "classnames";
// @material-ui/core components
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import ButtonBase from '@material-ui/core/ButtonBase';
// core components
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import NavPills from "../../components/NavPills/NavPills.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import Muted from "../../components/Typography/Muted.js";
import Parallax from "../../components/Parallax/Parallax.js";
import Clearfix from "../../components/Clearfix/Clearfix.js";
import Button from "../../components/CustomButtons/Button.js";
import avatar from "../../assets/img/spotify4.png";
import youtube from "../../assets/img/youtube2.jpeg";
import apple from "../../assets/img/apple2.jpeg";
import soundcloud from "../../assets/img/soundcloud3.png";
import marc from "../../assets/img/faces/marc.jpg";
import kendall from "../../assets/img/faces/kendall.jpg";
import color1 from "../../assets/img/examples/card-blog8.jpg";
import cardProfile2Square from "../../assets/img/faces/card-profile2-square.jpg";
import {Img} from 'react-image'


import profilePageStyle from "../../assets/jss/material-ui/views/profilePageStyle.js";
var qs = require('qs');
const useStyles = (profilePageStyle);
class Profile extends Component {
    state = {
        checkedA: true,
        setCheckedA: true
    }
    
    componentWillMount() {
        this.props.onFetchUser(this.props.token)
        this.props.onSpotifyAuthCheck(this.props.token)
    }
   
    handleClick = (props) => {
        this.props.history.push('/channel');
    }

    handleSpotifyClick = (props) => {
    }
    getHashParams() {
        var hashParams = {};
        var e, r = /([^&;=]+)=?([^&;]*)/g,
            q = window.location.hash.substring(1);
        e = r.exec(q)
        while (e) {
           hashParams[e[1]] = decodeURIComponent(e[2]);
           e = r.exec(q);
        }
        return hashParams;
      }
    render () {
        
        let channelCode = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).code || ''
        
        let code = !isNaN(parseFloat(channelCode)) ? channelCode : ""
        
        const { classes } = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid,
            classes.imgDimention
          );
        return (
            
            <div className={classes.ProfileContent}>
                <Parallax
                    image={require("../../assets/img/bg4.jpg")}
                    filter="dark"
                    className={classes.parallax}
                />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                            
                            <div className={classes.profile}>
                                
                                <div>
                                {/* <img src='data:image/jpeg;base64," + encode(img.Body) '/> */}
                                <Img src={[`https://apprave.s3.amazonaws.com/${this.props.userId}.jpg`, "https://apprave.s3.amazonaws.com/users/default.jpg"]} className={imageClasses} />
                                </div>
                                <div className={classes.name}>
                                {this.props.user  ?
                                    <div>
                                        <h3 className={classes.title}>{this.props.user.spotifyDisplayName}</h3>
                                        <h4>{this.props.user.email}</h4>
                                    </div>
                                               
                                                
                                                : 
                                                <div>
                                                    <h3 className={classes.title}></h3>
                                                    <h4>Anonymous User</h4>
                                                </div>
                                                
                                                }
        
                                </div>
                            </div>
            
                            
                            </GridItem>
                        </GridContainer>
                        {/* <div className={classNames(classes.description, classes.textCenter)}>
                            <p>
                            An artist of considerable range, Chet Faker — the name taken by
                            Melbourne-raised, Brooklyn-based Nick Murphy — writes, performs
                            and records all of his own music, giving it a warm, intimate feel
                            with a solid groove structure.{" "}
                            </p>
                        </div> */}
                        <div className={classes.profileTabs}>
                            <NavPills
                            alignCenter
                            color="primary"
                            className={classes.navPills}
                            tabs={[
                                {
                                tabButton: "Applications",
                                tabIcon: Palette,
                                tabContent: (
                                    <div> 

                                    <GridContainer justify="center">
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile media className={classes.card}>
                                            <GridContainer className={classes.gridContainer}>
                                                <GridItem xs={12} sm={12} md={5}>
                                                    <CardHeader image media>
                                                    <a className={classes.buttonLlink} href={`https://api.moowzi.com/spotifyauth?userAccessToken=${this.props.token}&path=${code}`}>
                                                        <img src={avatar} alt="..." className={classes.imgDimention} />
                                                    </a>
                                                    <div
                                                        className={classes.coloredShadow}
                                                        style={{
                                                        backgroundImage: "url(" + avatar + ")",
                                                        opacity: "1"
                                                        }}
                                                    />
                                                    </CardHeader>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={7}>
                                                    <CardBody plain>
                                                    <h4 className={classes.cardTitle}>
                                                        Spotify Authentication
                                                    </h4>
                                                    {!this.props.isSpotifyAuthenticated ?
                                                    <h3 className={classes.cardTitle}>
                                                        REQUIRED 
                                                    </h3>
                                                    
                                                    : 
                                                    <h3 className={classes.cardTitle}>SUCCESS!</h3>   
                                                    }
                                                    </CardBody>
                                                </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile media soon className={classes.card}>
                                            <GridContainer className={classes.gridContainer}>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image media>
                                                <a className={classes.buttonLlink} href={`https://www.youtube.com/`}>
                                                    <img src={youtube} alt="..." className={classes.imgDimention}/>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + avatar + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h3 className={classes.cardTitle}>
                                                    Youtube Authentication
                                                </h3>
                                                <h3 className={classes.cardTitle}>COMING SOON!</h3>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile media soon className={classes.card}>
                                            <GridContainer className={classes.gridContainer}>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image media>
                                                <a className={classes.buttonLlink} href={`https://www.apple.com/apple-music/`}>
                                                    <img src={apple} alt="..." className={classes.imgDimention} />
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + avatar + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h3 className={classes.cardTitle}>
                                                    iTunes Authentication
                                                </h3>
                                                <h3 className={classes.cardTitle}>COMING SOON!</h3>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile media soon className={classes.card}>
                                            <GridContainer className={classes.gridContainer}>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image media>
                                                <a className={classes.buttonLlink} href={`https://soundcloud.com/`}>
                                                    <img src={soundcloud} alt="..." className={classes.imgDimention}/>
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + avatar + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h3 className={classes.cardTitle}>
                                                    Soundcloud Authentication
                                                </h3>
                                                <h3 className={classes.cardTitle}>COMING SOON!</h3>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                    </GridContainer>
                                    </div>
                                    
                                    
                                )
                                },
                                {
                                    tabButton: "Channels",
                                    tabIcon: Palette,
                                    tabContent: (
                                        <div >
                                            <GridContainer justify="center">
                                                <GridItem
                                                xs={12}
                                                sm={12}
                                                md={15}
                                                className={classes.gridItem}
                                                >
                                                    <Link to={'/channel'}>
                                                        <Card channelBackground style={{ backgroundImage: `url(${color1})` }}>
                                                            

                                                            <CardBody plain>
                                                                <h2
                                                                className={classNames(classes.cardCategory, classes.textInfo)}
                                                                >
                                                                {"No Channels associated"}
                                                                </h2>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )   
                                },
                                {
                                tabButton: "Connections",
                                tabIcon: People,
                                tabContent: (
                                    <div>
                                    <GridContainer justify="center">
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile plain className={classes.card}>
                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image plain>
                                                <a href="#pablo">
                                                    <img src={kendall} alt="..." />
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + avatar + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h4 className={classes.cardTitle}>
                                                    Gigi Hadid
                                                </h4>
                                                <Muted>
                                                    <h6>MODEL</h6>
                                                </Muted>
                                                <p className={classes.description}>
                                                    Don{"'"}t be scared of the truth because we
                                                    need to restart the human foundation in
                                                    truth...
                                                </p>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile plain className={classes.card}>
                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image plain>
                                                <a href="#pablo">
                                                    <img src={marc} alt="..." />
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + marc + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h4 className={classes.cardTitle}>
                                                    Marc Jacobs
                                                </h4>
                                                <Muted>
                                                    <h6>DESIGNER</h6>
                                                </Muted>
                                                <p className={classes.description}>
                                                    Don{"'"}t be scared of the truth because we
                                                    need to restart the human foundation in
                                                    truth...
                                                </p>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer justify="center">
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile plain className={classes.card}>
                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image plain>
                                                <a href="#pablo">
                                                    <img src={kendall} alt="..." />
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage: "url(" + kendall + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h4 className={classes.cardTitle}>
                                                    Kendall Jenner
                                                </h4>
                                                <Muted>
                                                    <h6>MODEL</h6>
                                                </Muted>
                                                <p className={classes.description}>
                                                    I love you like Kanye loves Kanye. Don
                                                    {"'"}t be scared of the truth.
                                                </p>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                        <GridItem
                                        xs={12}
                                        sm={12}
                                        md={5}
                                        className={classes.gridItem}
                                        >
                                        <Card profile plain className={classes.card}>
                                            <GridContainer>
                                            <GridItem xs={12} sm={12} md={5}>
                                                <CardHeader image plain>
                                                <a href="#pablo">
                                                    <img src={cardProfile2Square} alt="..." />
                                                </a>
                                                <div
                                                    className={classes.coloredShadow}
                                                    style={{
                                                    backgroundImage:
                                                        "url(" + cardProfile2Square + ")",
                                                    opacity: "1"
                                                    }}
                                                />
                                                </CardHeader>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={7}>
                                                <CardBody plain>
                                                <h4 className={classes.cardTitle}>
                                                    George West
                                                </h4>
                                                <Muted>
                                                    <h6>MODEL/DJ</h6>
                                                </Muted>
                                                <p className={classes.description}>
                                                    I love you like Kanye loves Kanye.
                                                </p>
                                                </CardBody>
                                            </GridItem>
                                            </GridContainer>
                                        </Card>
                                        </GridItem>
                                    </GridContainer>
                                    </div>
                                )
                                }
                            ]}
                            />
                        </div>
                        <Clearfix />
                    </div>
                </div>
                <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                />
               
            </div>
        );
    }
}

const mapStateToProps = state => {
    
    return {
        token: state.auth.token,
        user:  state.user.user,
        userId: state.auth.userId,
        isAuthenticated: state.auth.token !== null,
        isSpotifyAuthenticated: state.spotifyAuthCheck.spotifyAuthToken 
        
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUser: (token) => dispatch(actions.fetchUser(token)),
        onSpotifyAuthCheck: ( token ) => dispatch( actions.spotifyAuthCheck(token) ),
    };
};


Profile.propTypes = {
    classes: PropTypes.object.isRequired,
    onFetchUser: PropTypes.func.isRequired
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(Profile )));
