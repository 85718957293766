import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import registerServiceWorker from './registerServiceWorker';
import authReducer from './store/reducers/auth';
import channelReducer from './store/reducers/channel';
import trackReducer from './store/reducers/track';
import spotifyReducer from './store/reducers/spotifyAuthCheck';
import userReducer from './store/reducers/user';
import './index.css';
import App from './App';


const savetoLocalStorage = (state) => {
    try {
        const serialState = JSON.stringify(state)
        localStorage.setItem('state',serialState)
        
    }catch(error) {
        console.log("Persist State error", error)
    }
}

const loadFromLocalStorage = () => {
    try {
        const serialState = localStorage.getItem('state')
        if (serialState === null) return undefined
        return JSON.parse(serialState)
    }
    catch (error) {
        console.log("Persist State error", error)
        return undefined
    }

}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appReducer = combineReducers({
    auth: authReducer,
    channels: channelReducer,
    tracks: trackReducer,
    spotifyAuthCheck: spotifyReducer,
    user: userReducer
});

const persistedState = loadFromLocalStorage()

const rootReducer = (state, action) => {
    
    if (action.type === 'AUTH_LOGOUT') {
        state = undefined
      }
    return appReducer(state, action)
    
  }

const store = createStore(rootReducer, 
    persistedState,
    composeEnhancers(
    applyMiddleware(thunk)
));

store.subscribe(()=> savetoLocalStorage(store.getState()))

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
