import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { fetchTracksStart } from '../actions/track';

const initialState = {
    token: null,
    tracks: [],
    error: null,
    loading: false
};

const fetchTrackStart = (state, action) => {
    return updateObject( state, { loading: true } );
}

const fetchTracksSuccess = ( state, action ) => {
    return updateObject( state, {
        tracks: action.tracks,
        loading: false
    } );
};

const fetchTracksFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_TRACKS_START: return fetchTracksStart( state, action );
        case actionTypes.FETCH_TRACKS_SUCCESS: return fetchTracksSuccess( state, action );
        case actionTypes.FETCH_TRACKS_FAIL: return fetchTracksFail( state, action );
        default: return state;
    }
    
};

export default reducer;