import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const styles = {
  root: {
    '& label.Mui-focused': {
        color: 'white',
      },
    // background: "white",
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'yellow',
        },
    }
  },
  input: {
    color: "white",
    fontFamily: "Asap Condensed"
  },
  inputLabel: {
    fontFamily: "Asap Condensed",
    color: 'white'
  },

};

function CustomizedInputs(props) {
  const { classes } = props;  
  return (
    <TextField
    type ={props.type}
    label= {props.label}
    defaultValue = {props.defaultValue} 
    InputLabelProps={{
        className: classes.inputLabel
    }}
    variant="outlined"
      className={classes.root}
      InputProps={{
        className: classes.input
      }}
      fullWidth
      onChange={props.onChange}
    />
  );
}


CustomizedInputs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedInputs);
