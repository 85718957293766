import {
  section,
  container,
  cardTitle,
  coloredShadow,
  mlAuto,
  mrAuto,
  main,
  whiteColor,
  mainRaised,
  grayColor
} from "../../../material-ui.js";

import customCheckboxRadioSwitch from "../../../material-ui/customCheckboxRadioSwitchStyle.js";

import tooltipsStyle from "../../../material-ui/tooltipsStyle.js";

const styles = {
  container,
  ...customCheckboxRadioSwitch,
  ...tooltipsStyle,
  checkRoot: {
    padding: "14px",
    "&:hover": {
      backgroundColor: "unset"
    }
  },

  main: {
    ...main
  },
  mainRaised: {
    ...mainRaised
  },
  coloredShadow,
  mlAuto,
  mrAuto,
  cardTitle: {
    ...cardTitle,
    textAlign: "center",
    marginBottom: "0px !important"
  },
  cardDescription: {
    color: grayColor[0],
    textAlign: "center"
  },
  description: {
    color: grayColor[0]
  },
  section: {
    ...section,
    padding: "70px 0px"
  },
  priceContainer: {
    display: "inline-flex"
  },
  price: {
    fontSize: "18px",
    color: grayColor[22]
  },
  pullRight: {
    float: "right"
  },
  cardHeaderImage: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    marginLeft: "15px",
    marginRight: "15px",
    marginTop: "-30px",
    borderRadius: "6px",
    "& img": {
      width: "100%",
      borderRadius: "6px",
      pointerEvents: "none"
    },
    "& a": {
      display: "block"
    }
  },
  justifyContentBetween: {
    WebkitBoxPack: "justify!important",
    justifyContent: "space-between !important"
  },
  customExpandPanel: {
    maxHeight: "273px",
    overflowY: "scroll",
    "&  label": {
      display: "block"
    }
  },
  priceSlider: {
    fontWeight: "500"
  },
  refineButton: {
    margin: "-3px 0"
  },
  cardBodyRefine: {
    paddingLeft: "15px",
    paddingRight: "15px"
  },
  textLeft: {
    textAlign: "left"
  },
  Channels: {
    marginTop: "72px",
    textAlign: "center",
    position: "relative"
},
ChannelButtonContainer: {
    marginTop: "2%",
    width: "50%"
},
channelContainer:{
    display: "flex",
    marginLeft: "15%",
    // marginTop: "75px"
},

GridClass: {
    float: "left",
    // margin: "3%"
},
CreatedChannelText: {
    textAlign: "left",
    position: "relative",
    marginLeft: "3%"
},
AllChannelContainer: {
    // padding: "3%",
},
Wrapper: {
  display: "flex",
  /* grid-template-columns: repeat(3,100%); */
  overflow: "scroll",
  flexDirection: "row",
  scrollBehavior: "smooth",
  "-webkit-scrollbar": {
    width: "12px"
  }
},
ChannelsGrid: {
  flexBasis: "100%",
  maxWidth: "100%"
},
ChannelCardBody: {
  zIndex: "2",
  position: "relative",
  width: "300px",
  minHeight: "200px",
  paddingTop: "40px",
  paddingBottom: "40px"
},
list: {
  marginBottom: "0",
  padding: "0",
  marginTop: "0"
},
left: {
  float: "left!important",
  display: "block"
},
right: {
  padding: "15px 0",
  margin: "0",
  float: "right"
},
icon: {
  width: "18px",
  height: "18px",
  top: "3px",
  position: "relative"
},
listUnstyled: {
  paddingLeft: "0",
  listStyle: "none",
  "& > li": {
    padding: "5px 0px",
    fontSize: "1em"
  }
},
inlineBlock: {
  display: "inline-block",
  padding: "0px",
  width: "auto"
},
block: {
  color: "inherit",
  padding: "0.9375rem",
  fontWeight: "500",
  fontSize: "12px",
  textTransform: "uppercase",
  borderRadius: "3px",
  textDecoration: "none",
  position: "relative",
  display: "block"
},
parallax: {
  height: "480px",
  backgroundPosition: "top center"
},
main:{
  background: "white",
  position: "relative",
  zIndex: "3",
  color: "black" 
},
// mainRaised:{
//   marginLeft:"20px",
// }
};

export default styles;
