import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const StyledButton = withStyles({
  root: {
    fontFamily: "Asap Condensed" , 
    fontStyle: 'bold',
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 1,
    width: "50%",
    color: "white",
    height: 48,
    padding: "0 30px",
    marginTop: "1%"
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)"
  },
  label: {
    // textTransform: 'capitalize',
  }
})(Button);


export default function CustomizedButton(props) {
  return <StyledButton
  label="Submit" primary={true}  onClick={props.onClick}> {props.children}</StyledButton>;
}

