import React from 'react'

const About = (props) => {
    return (
        <div>
            <h1>About PAGE IS HERE</h1>
        </div>
    );
}

export default About;