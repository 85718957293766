import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
// import { joinChannelSuccess, joinChannelFail } from '../actions/channel';


const initialState = {
    token: null,
    channels: [],
    spotifyPlaylists:[],
    tracks:[],
    channeldetail: [],
    channelCreate: [],
    channelJoined: [],
    channeluploaded: null,
    error: null,
    loading: false,
    progressbar: false,
};

const fetchChannelsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const fetchChannelsSuccess = ( state, action ) => {
    return updateObject( state, {
        channels: action.channels,
        loading: false
    } );
};

const fetchChannelsFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};
const getSpotifyChannelsStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const getSpotifyChannelsSuccess = ( state, action ) => {
    return updateObject( state, {
        spotifyPlaylists: action.spotifyPlaylists,
        loading: false
    } );
};

const getSpotifyChannelsFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};
const fetchChannelDetailStart = ( state, action ) => {
    return updateObject( state, 
        { loading: true } );
};

const fetchChannelDetailSuccess = ( state, action ) => {
    return updateObject( state, {
        channeldetail: action.channeldetail,
        loading: false
    } );
};

const fetchChannelDetailFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const createChannelDetailFail = ( state, action ) => {
    return updateObject( state, { loading: false,  progressbar: false } );
};

const createChannelDetailStart = ( state, action ) => {
    return updateObject( state, { loading: true, progressbar: true } );
};

const createChannelDetailSuccess = ( state, action ) => {
    return updateObject( state, {
        channelCreate: action.channelCreate,
        progressbar: false
    } );
};


const joinChannelFail = ( state, action ) => {
    return updateObject( state, { loading: false,  progressbar: false } );
};

const joinChannelStart = ( state, action ) => {
    return updateObject( state, { loading: true, progressbar: true } );
};

const joinChannelSuccess = ( state, action ) => {
    return updateObject( state, {
        channelJoined: action.channelJoined,
        loading: false,
        progressbar: false
    } );
};

const uploadSpotifyChannelFail = ( state, action ) => {
    return updateObject( state, { loading: false } );
};

const uploadSpotifyChannelStart = ( state, action ) => {
    return updateObject( state, { loading: true } );
};

const uploadSpotifyChannelSuccess = ( state, action ) => {
    return updateObject( state, {
        channelUploaded: action.channeluploaded,
        loading: false
    } );
};




const setChannelRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_CHANNELS_START: return fetchChannelsStart( state, action );
        case actionTypes.FETCH_CHANNELS_SUCCESS: return fetchChannelsSuccess( state, action );
        case actionTypes.FETCH_CHANNELS_FAIL: return fetchChannelsFail( state, action );
        case actionTypes.FETCH_CHANNELDETAIL_START: return fetchChannelDetailStart(state, action);
        case actionTypes.FETCH_CHANNELDETAIL_FAIL: return fetchChannelDetailFail(state, action);
        case actionTypes.FETCH_CHANNELDETAIL_SUCCESS: return fetchChannelDetailSuccess(state, action);
        case actionTypes.CREATE_CHANNELDETAIL_START: return createChannelDetailStart(state, action);
        case actionTypes.CREATE_CHANNELDETAIL_FAIL: return createChannelDetailFail(state, action);
        case actionTypes.CREATE_CHANNELDETAIL_SUCCESS: return createChannelDetailSuccess(state, action);
        case actionTypes.JOIN_CHANNEL_START: return joinChannelStart( state, action );
        case actionTypes.JOIN_CHANNEL_SUCCESS: return joinChannelSuccess( state, action );
        case actionTypes.JOIN_CHANNEL_FAIL: return joinChannelFail( state, action );
        case actionTypes.UPLOAD_SPOTIFY_CHANNEL_START: return uploadSpotifyChannelStart( state, action );
        case actionTypes.UPLOAD_SPOTIFY_CHANNEL_SUCCESS: return uploadSpotifyChannelSuccess( state, action );
        case actionTypes.UPLOAD_SPOTIFY_CHANNEL_FAIL: return uploadSpotifyChannelFail( state, action );
        case actionTypes.GET_SPOTIFY_CHANNEL_START: return getSpotifyChannelsStart( state, action );
        case actionTypes.GET_SPOTIFY_CHANNEL_SUCCESS: return getSpotifyChannelsSuccess( state, action );
        case actionTypes.GET_SPOTIFY_CHANNEL_FAIL: return getSpotifyChannelsFail( state, action );
        case actionTypes.SET_CHANNEL_REDIRECT_PATH: return setChannelRedirectPath(state,action);
        default: return state;
    }
    
};

export default reducer;