import React, { Component } from 'react'
import {
    withRouter
  } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from "../../components/Footer/Footer.js";
import Parallax from "../../components/Parallax/Parallax.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import * as actions from '../../store/actions/index';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import StyledButton from '../../components/MaterialThemes/button'
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import color1 from "../../assets/img/examples/card-blog8.jpg";
import channelsPageStyle from "../../assets/jss/material-ui/views/ecommerceSections/productsStyle.js";
import classNames from "classnames";

const useStyles = (channelsPageStyle);
class Channels extends Component {
    componentDidMount () {
        this.props.onFetchChannels(this.props.token);
        this.props.onSpotifyChannels(this.props.token);
    }

    onclickHandler = (value) => {
        this.props.history.push('/channel/'+ value);
    }
    onCreateClickHandler = () => {
        this.props.history.push('/channel/create');
    }
    onJoinClickHandler = () => {
        this.props.history.push('/channel/join');
    }
    render() {
        const { classes } = this.props;
        //   let spotifyPlaylists = <Spinner />
          let createdChannels = 
            <div className={classes.GridClass}>
                <GridContainer>
                    <GridItem className={classes.ChannelsGrid} md={4} sm={4}>
                        <Card background style={{ backgroundImage: `url(${color1})` }}>
                            <ButtonBase
                                className={classes.cardAction}
                                >
                                <CardBody className={classes.ChannelCardBody}>
                                    <h2
                                    className={classNames(classes.cardCategory, classes.textInfo)}
                                    >
                                    {"No Channels associated"}
                                    </h2>
                                    
                                    <h4 className={classes.cardTitle}>
                                        {12444} 
                                    </h4>
                                </CardBody>
                        </ButtonBase>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        let joinedChannels = 
            <div className={classes.GridClass}>
                <GridContainer>
                    <GridItem className={classes.ChannelsGrid} md={4} sm={4}>
                        <Card background style={{ backgroundImage: `url(${color1})` }}>
                            <ButtonBase
                                className={classes.cardAction}
                                >
                                <CardBody className={classes.ChannelCardBody}>
                                    <h2
                                    className={classNames(classes.cardCategory, classes.textInfo)}
                                    >
                                    {"No Channels associated"}
                                    </h2>
                                    
                                    <h4 className={classes.cardTitle}>
                                        {12444} 
                                    </h4>
                                </CardBody>
                        </ButtonBase>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
              
       
        if (  this.props.channels && this.props.channels.createdChannels  && this.props.channels.createdChannels.length >0) {
            createdChannels = this.props.channels.createdChannels.map( channel => (
                <div className={classes.GridClass}>
                    <GridContainer>
                        <GridItem className={classes.ChannelsGrid} md={4} sm={4}>
                            <Card background style={{ backgroundImage: `url(${color1})` }}>
                                <ButtonBase
                                    className={classes.cardAction}
                                    onClick={()=> this.onclickHandler(channel.channelId)}>
                                    <CardBody className={classes.ChannelCardBody}>
                                        <h2
                                        className={classNames(classes.cardCategory, classes.textInfo)}
                                        >
                                        {channel.name || "Lane"}
                                        </h2>
                                        
                                        <h4 className={classNames(classes.cardCategory, classes.textInfo)}>
                                            {channel.code}
                                        </h4>
                                    </CardBody>
                            </ButtonBase>
                            </Card>
                        </GridItem>
                    </GridContainer>
                        {/* <SectionPills/> */}
                </div>
                
            ) )
        }
        if (  this.props.channels && this.props.channels.joinedChannels  && this.props.channels.joinedChannels.length >0) {
            joinedChannels = this.props.channels.joinedChannels.map( channel => (
                <div className={classes.GridClass}>
                    <GridContainer>
                        <GridItem className={classes.ChannelsGrid} md={4} sm={4}>
                            <Card background style={{ backgroundImage: `url(${color1})` }}>
                                <ButtonBase
                                    className={classes.cardAction}
                                    onClick={()=> this.onclickHandler(channel.channelId)}>
                                    <CardBody className={classes.ChannelCardBody}>
                                        <h2
                                        className={classNames(classes.cardCategory, classes.textInfo)}
                                        >
                                        {channel.name || "Lane"}
                                        </h2>
                                        
                                        <h4 className={classNames(classes.cardCategory, classes.textInfo)}>
                                            {channel.code}
                                        </h4>
                                    </CardBody>
                            </ButtonBase>
                            </Card>
                        </GridItem>
                    </GridContainer>
                        {/* <SectionPills/> */}
                </div>
                
            ) )
        }
        // if ( !this.props.loading && this.props.spotifyPlaylists) {
        //     spotifyPlaylists = this.props.spotifyPlaylists.map( channel => (
        //         <div className={classes.GridClass}>
        //             <GridContainer>
        //                 <GridItem className={classes.ChannelsGrid} md={4} sm={4}>
        //                     <Card background style={{ backgroundImage: `url(${color1})` }}>
        //                         <ButtonBase
        //                             className={classes.cardAction}
        //                             onClick={()=> this.onclickHandler(channel.id)}>
        //                             <CardBody className={classes.ChannelCardBody}>
        //                                 <h2
        //                                 className={classNames(classes.cardCategory, classes.textInfo)}
        //                                 >
        //                                 {channel.name || "Lane"}
        //                                 </h2>
                                        
                       
        //                                 {/* <p className={classes.description}>
        //                                 Don{"'"}t be scared of the truth because we need to restart
        //                                 the human foundation in truth And I love you like Kanye loves
        //                                 Kanye I love Rick Owens’ bed design but the back is...
        //                                 </p> */}
        //                                 {/* <Button href="#" round color="white" onClick={()=> this.onclickHandler(channel.channelId)}>
        //                                 <Subject /> Read
        //                                 </Button> */}
        //                             </CardBody>
        //                     </ButtonBase>
        //                     </Card>
        //                 </GridItem>
        //             </GridContainer>
        //                 {/* <SectionPills/> */}
        //         </div>
                
        //     ) )
        // }
        return (
            <div className={classes.ChannelContent}>
                <Parallax
                    image={require("../../assets/img/dg3.jpg")}
                    filter="dark"
                    className={classes.parallax}
                />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <div className={classes.channelContainer}>
                            <div className={classes.ChannelButtonContainer}>
                                <StyledButton label="Submit" primary={true}  onClick={this.onCreateClickHandler}>
                                    CREATE
                                </StyledButton>
                            </div>
                            <div className={classes.ChannelButtonContainer}>
                                <StyledButton label="Submit" primary={true}  onClick={this.onJoinClickHandler}>
                                JOIN
                                </StyledButton>
                            </div>
                        </div>
                        <div className={classes.AllChannelContainer}>
                        
                            <div className={classes.CreatedChannel}>
                                <div className={classes.CreatedChannelText}>
                                    <h2>Your created Channels</h2>
                                </div>
                        
                                <div className={classes.Wrapper}>
                                    {createdChannels}
                                </div>
                            </div>
                            <div className={classes.CreatedChannel}>
                                <div className={classes.CreatedChannelText}>
                                    <h2>Your Joined Channels</h2>
                                </div>
                        
                                <div className={classes.Wrapper}>
                                    {joinedChannels}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer
                    content={
                    <div>
                        <div className={classes.left}>
                        <List className={classes.list}>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Moowzi 
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                About us
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a href="https://www.mousaic.com/" className={classes.block}>
                                Blog
                            </a>
                            </ListItem>
                            <ListItem className={classes.inlineBlock}>
                            <a
                                href="https://www.mousaic.com/"
                                target="_blank"
                                className={classes.block}
                            >
                                Licenses
                            </a>
                            </ListItem>
                        </List>
                        </div>
                        <div className={classes.right}>
                        &copy; {1900 + new Date().getYear()} , made with{" "}
                        {/* <Favorite className={classes.icon} /> by{" "} */}
                        <a
                            href="https://www.mousaic.com/"
                            target="_blank"
                        >
                            Moowzi
                        </a>{" "}
                        for a better web.
                        </div>
                    </div>
                    }
                />
                
            </div>
            
        );
    }
}
const mapStateToProps = state => {
    return {
        channels: state.channels.channels,
        spotifyPlaylists: state.channels.spotifyPlaylists,
        loading: state.loading,
        token: state.auth.token
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchChannels: (token) => dispatch( actions.fetchChannels(token) ),
        onSpotifyChannels: (token)=>dispatch(actions.getSpotifyChannels(token))
    };
};



Channels.propTypes = {
    classes: PropTypes.object.isRequired,
  };
export default withRouter(connect( mapStateToProps, mapDispatchToProps )( withStyles(useStyles)(Channels )));
