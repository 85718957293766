import React from 'react'
import { Redirect,Route } from 'react-router-dom'
import {connect} from 'react-redux';
class ProtectedRoute extends React.Component {
    render() {
        const { component: Component, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={props =>
             localStorage.getItem('userId') ? (
              <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/' ,state: { from: this.props.location.pathname }}} />
            )
          }
        />
      );
    }
  }
  export default connect(state => state)(ProtectedRoute);